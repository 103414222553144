import $ from 'jquery';

export function stickyNavigation() {
	const sections = document.querySelectorAll('.js-section');
	const $dots = $('.js-dot');
	const $number = $('.js-sticky-number');
	const $label = $('.js-sticky-label');
	const elementStickyNav = document.querySelector('.js-sticky-nav');
	const degreesPerPage = 10;	

	let currentPage = 0;
	let sectionsOffset = [0];
	let sectionsLabel = [elementStickyNav.getAttribute('data-base-title')];
	let sectionsCounter;
	let scrollTimeout;
	let scrollThrottle = 50;
	let sectionOffset;

	for (let i = 0; i < sections.length; i++) {
		sectionOffset = 0;

		if ($(sections[i]).attr('data-anchor-offset') != undefined) {
			sectionOffset = Number($(sections[i]).attr('data-anchor-offset'));
		}

		sectionsOffset.push($(sections[i]).offset().top + sectionOffset - 10);
		sectionsLabel.push(sections[i].getAttribute('data-section-title'));
	}

	checkCurrentPage();
	scrollListener();

	/** 
	 * Listen to scroll event and update current page
     */
	function scrollListener() {
		$(window).scroll(() => {
			if (!scrollTimeout) {
				checkCurrentPage();
				resetScrollThrottle();
			}
		});
	}

	/** 
	 * Check current page with scroll distance
     */
	function checkCurrentPage() {
		sectionsCounter = 0;

		for (let x = 0; x < sectionsOffset.length; x++) {
			if ($(window).scrollTop() >= sectionsOffset[x]) {
				sectionsCounter = x;
			}
		}

		if (currentPage != sectionsCounter) {
			currentPage = sectionsCounter;
			updateCurrentPage();
		}
	}

	/** 
	 * Update current page in the UI
     */
	function updateCurrentPage() {
		$dots.removeClass('active');
		$dots[currentPage].classList.add('active');
		$label.text(sectionsLabel[currentPage]);
		$number.text('0' + Number(currentPage + 1));
		elementStickyNav.style.transform = `translate(0, -50%) rotate(-${degreesPerPage * currentPage}deg)`;

		$dots.addClass('faded');
		$dots[currentPage].classList.remove('faded');

		if ($dots[currentPage + 1] != undefined) {
			$dots[currentPage + 1].classList.remove('faded');
		}
		if ($dots[currentPage - 1] != undefined) {
			$dots[currentPage - 1].classList.remove('faded');
		}
	}

	/** 
	 * Reset scroll throttle
     */
	function resetScrollThrottle() {
		scrollTimeout = setTimeout(function () {
			scrollTimeout = null;
		}, scrollThrottle);
	}
}
