import $ from 'jquery';
import '../vendors/slick.min';

export function carousel() {
	$('.slider').slick({
		slide: '.slider__image',
		nextArrow: '.next',
		prevArrow: '.prev'
	});
}
