import $ from 'jquery';
import { scrollAnimations } from './scrollmagic';
import { WOW } from 'wowjs';
import { animationsWindowSize, animationsClass } from '../constants';

export function animations() {
    // Scroll animations only on desktop
    if ($(window).width() >= animationsWindowSize) {
        initAnimations();
    }

    // Listen to browser resize to launch scroll animations
    const mq = window.matchMedia(`(max-width: ${animationsWindowSize}px)`);
    const handleMQChange = () => {
        if ( !mq.matches && !$('body').hasClass(animationsClass) ){
            initAnimations();
        }
    }
    mq.addListener(handleMQChange);

    // Launch animations
    function initAnimations() {
        new WOW().init();
        scrollAnimations();
        $('body').addClass(animationsClass);
    }
}