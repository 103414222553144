import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import { TimelineMax } from 'gsap';
import '../vendors/animation.gsap';

export function scrollAnimations() {
    initScrollmagic();
}

function initScrollmagic() {
    var controller = createController({globalSceneOptions: {triggerHook: 'onEnter'}});

    var tweens = [
        [   // Banner
            TweenMax.to('.js-banner-content', 1, {opacity: '0.3', delay: 0}),
            TweenMax.to('.js-banner-content', 1, {top: '-100px', delay: 0}),
            TweenMax.to('.js-banner-shape', 1, {top: '28rem', delay: 0}),
            TweenMax.to('.js-banner-shape', 1, {scale: '0.9', delay: 0})
        ],
        [
            // About right image
            TweenMax.to('.js-about-background', 1, {y: '0', delay: 0}),
            TweenMax.to('.js-about-background', 1, {opacity: '1', delay: 0})
        ],
        [
            // About left image
            TweenMax.to('.js-about-image', 1, {y: '0', delay: 0}),
            TweenMax.to('.js-about-image', 1, {opacity: '1', delay: 0})
        ],
        [
            // Mission O background
            TweenMax.to('.js-0-background', 1, {y: '-200px', delay: 0})
        ],
        [
            // Mission shapes
            TweenMax.to('.js-mission-shapes', 1, {y: '400px', delay: 0})
        ],
        [
            // Team members
            TweenMax.to('.member-1, .member-2, .member-3', 1, {y: '0', delay: 0}),
            TweenMax.to('.member-1, .member-2, .member-3', 0.5, {opacity: '1', delay: 0})
        ]
    ];

    var scenes = [
        {   // Banner
            duration: '100%',
            offset: -100,
            triggerElement: '.main-content'
        },
        {
            // About right image
            duration: '100%',
            offset: 400,
            triggerElement: '.section-about'
        },
        {
            // About left image
            duration: '500',
            offset: -100,
            triggerElement: '.js-about-image'
        },
        {
            // Mission O background
            duration: '150%',
            offset: 200,
            triggerElement: '.section-mission'
        },
        {
            // Mission shapes
            duration: '300%',
            offset: 200,
            triggerElement: '.section-mission'
        },
        {
            // Team members
            duration: '100%',
            offset: -100,
            triggerElement: '.section-mission__team'
        }
    ]

    createAnimations(tweens, scenes, controller);
}

function createController(options) {
    return new ScrollMagic.Controller(options);
}

function createTimeline(tweens) {
    return new TimelineMax().add(tweens);
}

function createScene(options, tweens, controller) {
    var scene = new ScrollMagic.Scene(options)
    .setTween(createTimeline(tweens))
	.addTo(controller);
}

function createAnimations(tweens, scenes, controller) {
    for(let i = 0; i < tweens.length; i++) {
        createScene(scenes[i], tweens[i], controller);
    }
}