import $ from 'jquery';

export function accordion() {
	const $accordionToggle = $('.js-accordion-toggle');

	$accordionToggle.click(function($event){
		$event.preventDefault();
		$($event.currentTarget.parentNode).toggleClass('is-opened');
	})
}
