import $ from 'jquery';

export function navigation() {
	const navTriggerClass = 'js-nav-trigger'
	const navClass = 'navigation';

	// Trigger opens the nav
	$(`.${navTriggerClass}`).click(() => {
		$('body').toggleClass('menu-opened');
	});

	// Closes the nav if user clicks outside
	$('body').click((e) => {
		const isTrigger = $(e.target).parents(`.${navTriggerClass}`).length || $(e.target).hasClass(navTriggerClass);

		if( $(e.target).closest(`.${navClass}`).length === 0 && !isTrigger) {
			$('body').removeClass('menu-opened');
		}
	});
}
