import $ from 'jquery';
import { mapStyles } from '../constants';

export { googleMap }

function googleMap() {
	var map = document.querySelector('#map');
	
	if ( map != null ) {
		createMap();
	}
}

function createMap() {
	var latitude = document.querySelector('#map').getAttribute('data-latitude');
	var longitude = document.querySelector('#map').getAttribute('data-longitude');
	var myLatLng = {lat: Number(latitude), lng: Number(longitude)};
	var theMarker = {lat: myLatLng.lat, lng: myLatLng.lng};
	var icon = {
		url: assets + "/images/marker.png",
		scaledSize: new google.maps.Size(40, 60)
	};

	// Create a map object and specify the DOM element for display.
	var map = new google.maps.Map(document.getElementById('map'), {
		center: myLatLng,
		scrollwheel: false,
		styles: mapStyles,
		zoom: 15,
		fullscreenControl: false,
		zoomControl: false,
		streetViewControl: false,
		mapTypeControl: false
	});

	// Create a marker and set its position.
	var marker = new google.maps.Marker({
		map: map,
		position: theMarker,
		icon: icon
	});	

	marker.setMap(map);
}
