import $ from 'jquery';

export function loadNews() {

	if ($('.js-single-news').length <= 4) {
		$('.js-load-more').hide();
	}

	$('.js-load-more').click($event => {
		$event.preventDefault();

		$('.js-news-wrapper').addClass('show-all');
	})
}
