import './modules/helpers';
import { scroller } from './modules/scroller';
import { navigation } from './modules/navigation';
import { googleMap } from './modules/map';
import { stickyNavigation } from './modules/sticky-navigation';
import { animations } from './modules/animations';
import { loadNews } from './modules/news';
import { accordion } from './modules/accordion';
import { carousel } from './modules/carousel';

scroller();

// Homepage scripts
if (document.querySelector('#homepage')) {
    googleMap();
    navigation();
    stickyNavigation();
    animations();
    loadNews();
    accordion();
    carousel();
}