import $ from 'jquery';
import { animationsWindowSize } from '../constants';

export function scroller() {

	let speed = 1200;

	$('.scrollTo').click(function(event){
		event.preventDefault();

		if ($(this).attr('data-top-page') !== undefined) {
			$('html, body').animate({ scrollTop: 0 }, 1200);
		} else {
			const selectedHref = $(this).attr('href');
			let offset = 0;
			
			if ($("#"+selectedHref).attr('data-anchor-offset') != undefined) {
				offset = Number($("#"+selectedHref).attr('data-anchor-offset'));
			}

			smoothScroll(selectedHref, offset);
			$('body').removeClass('menu-opened');
		}
		
		return false;
	});

	function smoothScroll(section, offset = 0){
		if ($(window).width() < 768) {
			offset = 0;
			speed = 800;
		}
		$('html, body').animate({ scrollTop: $("#"+section).offset().top + offset }, speed);
	} 

}
